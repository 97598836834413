<template>
  <nav class="footer-top-middle-navigation" aria-label="">
    <!--    todo: get translated title from api -->
    <h3 class="title font-md-bold">{{ messages[locale].title }}</h3>
    <ul class="list -group1">
      <li v-for="item in group1" :key="`tree-1-${item.url}`" class="item">
        <MenuLink class="link nav-link-tertiary" :data="item" />
      </li>
    </ul>
    <ul class="list -group2">
      <li v-for="item in group2" :key="`tree-1-${item.url}`" class="item">
        <MenuLink class="link nav-link-tertiary" :data="item" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { Locale } from '@intlify/core-base';
import MenuLink from '~/components/links/MenuLink.vue';

const { data } = await useAsyncData(`footer-top-middle-navigation`, () =>
  $fetch('/api/content/get-menu-by-name', {
    query: { name: 'FOOTER_TOP_MIDDLE' },
  }),
);

const navLinks = computed(function () {
  return data.value?.data.menu?.items ?? [];
});

// split navLinks in two groups
const half = Math.ceil(navLinks.value.length / 2);
const group1 = navLinks.value.slice(0, half);
const group2 = navLinks.value.slice(half);

const { locale } = useI18n();

const messages: Record<Locale, Record<string, string>> = {
  en: {
    title: 'Get to know us',
  },
  de: {
    title: 'Lernen Sie uns kennen',
  },
};
</script>

<style scoped lang="scss">
.footer-top-middle-navigation {
  @media (--vs) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1rem;
  }

  > .list {
    &.-group1 {
      @media (--vs) {
        grid-column: 1 / 2;
      }
    }

    &.-group2 {
      @media (--vs) {
        grid-column: 2 / 3;
      }
    }

    > .item {
      @media (--vs) {
        padding-bottom: 0.25rem;
      }

      @media (--vl) {
        padding-bottom: 0.38rem;
      }
    }
  }
}
</style>
