<template>
  <a
    v-if="linkType === 'internal'"
    class="internal-link"
    :href="`https://www.zuehlke.com${path}`"
  >
    <slot />
  </a>
  <a v-else class="external-link" :href="path">
    <slot />
  </a>
</template>

<script setup lang="ts">
const props = defineProps<{
  path: string;
  isInternal?: boolean;
}>();

const linkType = computed(function () {
  if (props.isInternal) {
    return 'internal';
  }

  if (props.path.substring(0, 4) === 'http') {
    return 'external';
  } else if (props.path.substring(0, 6) === 'mailto') {
    return 'email';
  } else if (props.path.substring(0, 3) === 'tel') {
    return 'phone';
  }

  return 'internal';
});
</script>

<style scoped lang="scss"></style>
