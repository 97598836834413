export function useViewportSize() {
  const { width } = useWindowSize();

  const isVs = computed(function () {
    return width.value < 900;
  });

  const isVl = computed(function () {
    return width.value >= 900;
  });

  return { isVs, isVl };
}
