<template>
  <div v-if="content" class="block-content-cta">
    <h3 v-if="content.title" class="bigtitle font-2xl-bold">
      {{ content.title }}
    </h3>
    <p v-if="content.text" class="text font-lg-medium-vl">
      {{ content.text }}
    </p>
    <LinkHandler
      v-if="content.link?.url"
      :is-internal="content.link.internal"
      class="button-primary"
      :path="content.link.url"
      ><span class="label">{{ content.title }}</span>
    </LinkHandler>
  </div>
</template>

<script setup lang="ts">
import LinkHandler from '~/components/links/LinkHandler.vue';

const { locale } = useI18n();

const { data, refresh } = await useAsyncData(
  `block-content-cta-${locale.value}`,
  () =>
    $fetch('/api/content/get-block-content-cta', {
      query: { langcode: locale.value },
    }),
);

const content = computed(function () {
  return data.value?.data.blockContentCta;
});

watch(locale, function () {
  refresh();
});
</script>

<style scoped lang="scss">
.block-content-cta {
  > .bigtitle {
    @media (--vs) {
      padding-bottom: 1rem;
    }

    @media (--vl) {
      padding-bottom: 1.5rem;
    }
  }

  > .text {
    @media (--vs) {
      padding-bottom: 1.5rem;
      text-wrap: pretty;
    }

    @media (--vl) {
      padding-bottom: 3rem;
    }
  }
}
</style>
