<template>
  <div v-if="content" class="block-content-address">
    <!--    todo: get translated title from api -->
    <h3 class="title font-md-bold">{{ messages[locale].title }}</h3>
    <div v-if="content.body" class="content" v-html="content.body.value"></div>
  </div>
</template>

<script setup lang="ts">
import type { Locale } from '@intlify/core-base';

const { locale } = useI18n();

const { data, refresh } = await useAsyncData(
  `block-content-address-${locale.value}`,
  () =>
    $fetch('/api/content/get-block-content-address', {
      query: { langcode: locale.value },
    }),
);

const content = computed(function () {
  return data.value?.data?.blockContentAddress;
});

watch(locale, function () {
  refresh();
});

const messages: Record<Locale, Record<string, string>> = {
  en: {
    title: 'Contact',
  },
  de: {
    title: 'Kontakt',
  },
};
</script>

<style scoped lang="scss">
.block-content-address {
  > .content {
    :deep(a) {
      color: var(--color-topaz-800);
      text-decoration: none;
    }

    :deep(.link) {
      @media (--vs) {
        padding-top: 0.25rem;
      }

      @media (--vl) {
        padding-top: 0.38rem;
      }
    }
  }
}
</style>
