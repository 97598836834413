<template>
  <nav class="footer-navigation">
    <!--    todo: get translated title from api -->
    <h3 class="title font-md-bold">{{ messages[locale].title }}</h3>
    <ul class="list">
      <li v-for="item in navLinks" :key="`tree-1-${item.url}`" class="item">
        <MenuLink class="link nav-link-tertiary" :data="item" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { Locale } from '@intlify/core-base';
import MenuLink from '~/components/links/MenuLink.vue';

const { locale } = useI18n();

const { data } = await useAsyncData(`footer-navigation`, () =>
  $fetch('/api/content/get-menu-by-name', {
    query: { name: 'FOOTER', langcode: locale.value },
  }),
);

const navLinks = computed(function () {
  return data.value?.data.menu?.items ?? [];
});

const messages: Record<Locale, Record<string, string>> = {
  en: {
    title: 'Legal',
  },
  de: {
    title: 'Rechtliches',
  },
};
</script>

<style scoped lang="scss">
.footer-navigation {
  > .list > .item {
    @media (--vs) {
      padding-bottom: 0.25rem;
    }

    @media (--vl) {
      padding-bottom: 0.38rem;
    }
  }
}
</style>
