import gsap from 'gsap';

export function useHoveredElement(options: {
  selectors: string[];
  onUpdate?: (hoveredElement: HTMLElement | null) => void;
}) {
  const elements = ref<HTMLElement[]>([]);

  const state = ref<{
    currentHoveredElement: null | HTMLElement;
  }>({
    currentHoveredElement: null,
  });

  const hasHover = computed(function () {
    return state.value.currentHoveredElement !== null;
  });

  options.selectors.forEach(function (s) {
    const els = gsap.utils.toArray(s) as HTMLElement[];

    els.forEach((el) => elements.value.push(el));
  });

  for (let i = 0; i < elements.value.length; i++) {
    const el = elements.value[i];

    el.addEventListener('mouseenter', () => onElMouseEnter(el));
    el.addEventListener('mouseleave', () => onElMouseLeave(el));
  }

  return { hasHover, state };

  function onElMouseEnter(el: HTMLElement) {
    state.value.currentHoveredElement = el;

    onStateUpdate();
  }

  function onElMouseLeave(el: HTMLElement) {
    if (el === state.value.currentHoveredElement) {
      state.value.currentHoveredElement = null;
    }

    onStateUpdate();
  }

  function onStateUpdate() {
    if (options.onUpdate) {
      options.onUpdate(state.value.currentHoveredElement);
    }
  }
}
