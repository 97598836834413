import { useLerpedMouseState } from '~/utils/useLerpedMouseState';
import { useHoveredElement } from '~/utils/useHoveredElement';
import { useViewportSize } from '~/utils/useViewportSize';

export function useCustomCursor() {
  const preferredMotion = usePreferredReducedMotion();
  const { isVs } = useViewportSize();

  const el = ref<HTMLElement | null>(null);

  const { state, stop } = useLerpedMouseState({ onUpdate });

  onMounted(function () {
    if (isVs.value || preferredMotion.value === 'reduce') {
      stop();

      return;
    }

    useHoveredElement({
      selectors: [
        `[data-cursor="dive-deeper"]`,
        `[data-cursor="quicklook"]`,
        `[data-cursor="arrow"]`,
      ],
      onUpdate: onHoveredElementUpdate,
    });

    el.value = getCursorElement();

    document.body.appendChild(el.value);
  });

  onUnmounted(function () {
    if (el.value) {
      document.body.removeChild(el.value);

      stop();
    }
  });

  function onUpdate() {
    if (el.value) {
      el.value.style.top = `${state.value.y}px`;
      el.value.style.left = `${state.value.x}px`;
    }
  }

  function onHoveredElementUpdate(hoveredElement: HTMLElement | null) {
    if (hoveredElement) {
      const type = getCursorTypeFromElement(hoveredElement);

      updateCursor(type);
    } else {
      updateCursor(null);
    }
  }

  function updateCursor(type: string | null) {
    if (!el.value) {
      return;
    }

    if (type === null) {
      el.value.setAttribute('data-active-cursor', 'null');
    } else {
      el.value.setAttribute('data-active-cursor', type);
    }
  }
}

function getCursorElement() {
  const div = document.createElement('div');

  div.classList.add('custom-cursor');
  div.setAttribute('role', 'presentation');
  div.innerHTML = `
    <div class="circle"></div>
    <div class="background -arrow"></div>
    <div class="background -dive-deeper"></div>
    <div class="background -quicklook"></div>
  `;

  return div;
}

function getCursorTypeFromElement(el: HTMLElement) {
  const attr = el.getAttribute('data-cursor');

  if (attr === 'dive-deeper' || attr === 'quicklook' || attr === 'arrow') {
    return attr;
  }

  return null;
}
