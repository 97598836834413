<template>
  <div class="app-base">
    <div class="sticky-footer">
      <SiteHeader />
      <NuxtPage />
      <SiteFooter />
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

useCustomCursor();
</script>

<style scoped lang="scss">
.app-base {
  max-width: var(--site-max-width, 0);
  margin: 0 var(--site-outer-margin-inline);
}
</style>
