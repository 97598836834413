export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","content":"#fff"},{"name":"application-name","content":"Zuehlke Website"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[{"defer":true,"innerHTML":"\n        (function (sCDN, sCDNProject, sCDNWorkspace, sCDNVers) {\n  if (\n    window.localStorage !== null &&\n    typeof window.localStorage === 'object' &&\n    typeof window.localStorage.getItem === 'function' &&\n    window.sessionStorage !== null &&\n    typeof window.sessionStorage === 'object' &&\n    typeof window.sessionStorage.getItem === 'function'\n  ) {\n    sCDNVers =\n      window.sessionStorage.getItem('jts_preview_version') ||\n      window.localStorage.getItem('jts_preview_version') ||\n      sCDNVers;\n  }\n  window.jentis = window.jentis || {};\n  window.jentis.config = window.jentis.config || {};\n  window.jentis.config.frontend = window.jentis.config.frontend || {};\n  window.jentis.config.frontend.cdnhost =\n    sCDN + '/get/' + sCDNWorkspace + '/web/' + sCDNVers + '/';\n  window.jentis.config.frontend.vers = sCDNVers;\n  window.jentis.config.frontend.env = sCDNWorkspace;\n  window.jentis.config.frontend.project = sCDNProject;\n  window._jts = window._jts || [];\n  var f = document.getElementsByTagName('script')[0];\n  var j = document.createElement('script');\n  j.async = true;\n  j.src = window.jentis.config.frontend.cdnhost + 'vfqct2.js';\n  f.parentNode.insertBefore(j, f);\n})('https://8a2aj4.zuehlke.com', 'zuehlke', 'live', '_');\n"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false