<template>
  <nav class="social-media-links">
    <h3 class="_visually-hidden">{{ messages[locale].title }}</h3>
    <ul class="list">
      <li v-for="item in navLinks" :key="`tree-1-${item.url}`" class="item">
        <MenuLink
          class="link nav-link-tertiary"
          :data="item"
          :aria-label="item.url"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { Locale } from '@intlify/core-base';
import MenuLink from '~/components/links/MenuLink.vue';

const { locale } = useI18n();

const { data } = await useAsyncData(`social-media-links`, () =>
  $fetch('/api/content/get-menu-by-name', {
    query: { name: 'SOCIAL_MEDIA_LINKS', langcode: locale.value },
  }),
);

const navLinks = computed(function () {
  return data.value?.data?.menu?.items ?? [];
});

const messages: Record<Locale, Record<string, string>> = {
  en: {
    title: 'Follow us',
  },
  de: {
    title: 'Folgen Sie uns',
  },
};
</script>

<style scoped lang="scss">
.social-media-links {
  padding: 2rem 0 0 0;

  > .list > .item {
    @media (--vs) {
      padding-bottom: 0.25rem;
    }

    @media (--vl) {
      padding-bottom: 0.38rem;
    }
  }
}
</style>
