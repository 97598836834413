<template>
  <nav
    id="meta-navigation"
    class="meta-navigation font-sm-bold-vl font-md-bold-vs"
    :class="{ '_hidden-vs': !isOpen }"
    :aria-label="messages[locale].ariaLabelMetaNavigation"
  >
    <ul class="list">
      <li v-for="item in navLinks" :key="`tree-1-${item.title}`" class="item">
        <MenuLink
          :class="{
            '-active': activeMetaNavigation.url === item.url,
          }"
          class="link nav-link-primary"
          :data="item"
        />
      </li>
    </ul>
  </nav>
  <nav
    id="main-navigation"
    class="main-navigation font-md-bold-vl font-2xl-bold-vs"
    :class="{ '_hidden-vs': !isOpen }"
    :aria-label="messages[locale].ariaLabelMainNavigation"
  >
    <ul v-if="activeMetaNavigation" class="list">
      <li
        v-for="it in activeMetaNavigation.children"
        :key="`subtree-${it.title}`"
        ref="navListItems"
        class="item"
      >
        <MenuLink class="link nav-link-secondary" :data="it" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { type Locale } from '@intlify/core-base';
import type { MenuItem } from '~/drupal/types';
import MenuLink from '~/components/links/MenuLink.vue';

const props = defineProps<{
  isOpen: boolean;
}>();

const { locale } = useI18n();

const messages: Record<Locale, Record<string, string>> = {
  en: {
    ariaLabelMetaNavigation: 'Meta navigation',
    ariaLabelMainNavigation: 'Main navigation',
  },
  de: {
    ariaLabelMetaNavigation: 'Metanavigation',
    ariaLabelMainNavigation: 'Hauptnavigation',
  },
};

const { data } = await useAsyncData(`main-navigation`, () =>
  $fetch('/api/content/get-menu-by-name', {
    query: { name: 'MAIN_NAVIGATION', langcode: locale.value },
  }),
);

const navLinks = computed(function () {
  return data.value?.data.menu.items ?? [];
});

const activeMetaNavigation = ref<MenuItem>(navLinks.value[0]);

const navListItems = ref<HTMLElement[]>([]);

const fadeInAnimation = gsap.timeline();

onMounted(() => {
  setTimeline();
  playFadeInAnimation();
});

watch(
  () => props.isOpen,
  (newOpen) => {
    if (newOpen) {
      playFadeInAnimation();
    }
  },
);

function setTimeline() {
  fadeInAnimation.fromTo(
    navListItems.value,
    { autoAlpha: 0, y: -20 },
    {
      autoAlpha: 1,
      stagger: 0.05,
      y: 0,
    },
  );
}

function playFadeInAnimation() {
  fadeInAnimation.play(0);
}
</script>

<style scoped lang="scss">
.meta-navigation {
  grid-row: 1 / 3;
  grid-column: 3 / 4;

  > .list {
    display: flex;

    @media (--vs) {
      gap: 1rem;
    }

    @media (--vl) {
      gap: 1.5rem;
    }

    > .item {
      display: flex;

      > .link {
        @media (--vs) {
          padding-top: 4.19rem;
          margin-bottom: 4.31rem;
        }

        @media (--vl) {
          padding-top: 1rem;
        }
      }
    }
  }
}

.main-navigation {
  grid-row: 1 / 3;
  grid-column: 2 / 3;

  @media (--vs) {
    align-self: flex-start;

    &.-hidden {
      display: none;
    }
  }

  @media (--vl) {
    padding: 0 0 0.5rem 2.25rem;
  }

  > .list {
    display: flex;
    height: 100%;

    @media (--vs) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
    }

    @media (--vl) {
      gap: var(--spacing-2xl);
    }

    > .item {
      display: flex;
      align-items: flex-end;

      > .link {
        height: 100%;
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
</style>
