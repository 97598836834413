<template>
  <button class="hamburger-menu" aria-label="toggle navigation">
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.hamburger-menu {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  width: 1.5rem;
  height: 0.875rem;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;

  &.-open {
    > .line:nth-child(1) {
      top: 6px;
      width: 0;
      left: 50%;
    }

    > .line:nth-child(2) {
      transform: rotate(45deg);
    }

    > .line:nth-child(3) {
      transform: rotate(-45deg);
    }

    > .line:nth-child(4) {
      top: 6px;
      width: 0;
      left: 50%;
    }
  }

  > .line {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--base-color-foreground);
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 6px;
    }

    &:nth-child(4) {
      top: 12px;
    }
  }
}

.screenreader-only {
  @include hide-visually();
}
</style>
