<template>
  <NuxtLink class="zuehlke-home-link" :to="localePath('/')">
    <span class="screenreader-only">Zühlke - zur Startseite</span>
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H70V70H0V0Z"
        fill="#965B9A"
      ></path>
      <path
        d="M6.88899 56.0993C6.568 56.7413 6.02479 57.1117 5.28405 57.1117C3.92602 57.1117 3.38281 55.9265 3.38281 54.7166C3.38281 53.4574 3.95071 52.3216 5.35812 52.3216C6.07417 52.3216 6.61738 52.6672 6.88899 53.3092C6.96306 53.4821 7.01244 53.6796 7.01244 53.8771C7.01244 54.05 6.91368 54.124 6.76553 54.1487L4.04948 54.5685C4.09886 55.3586 4.44454 56.3709 5.4075 56.3709C6.0001 56.3709 6.37047 56.0006 6.66676 55.5067C6.71615 55.408 6.74084 55.3092 6.88899 55.3092C7.01244 55.3092 7.06182 55.408 7.06182 55.5314C7.03713 55.7043 6.96306 55.9759 6.88899 56.0993ZM6.14824 53.8277C6.09886 53.3339 5.75318 52.8401 5.20997 52.8401C4.44454 52.8401 4.12355 53.4821 4.04948 54.1734L6.14824 53.8277Z"
        fill="white"
      ></path>
      <path
        d="M13.5761 56.8398C13.5761 56.9632 13.5514 57.0126 13.4279 57.0126H12.9588C12.8353 57.0126 12.8106 56.9879 12.8106 56.8398V53.9756C12.8106 53.383 12.7119 52.8398 11.9711 52.8398C11.6254 52.8398 11.3044 52.9879 11.1563 53.3336C11.1316 53.4324 11.1069 53.6299 11.1069 53.7534V56.8398C11.1069 56.9385 11.1069 57.0126 10.9588 57.0126H10.4649C10.3662 57.0126 10.3168 56.9632 10.3168 56.8645V53.8274C10.3168 53.2595 10.0946 52.8151 9.45259 52.8151C9.10692 52.8151 8.78593 52.9632 8.66247 53.3089C8.61309 53.4324 8.61309 53.6299 8.61309 53.778V56.8645C8.61309 56.9879 8.5884 57.0373 8.44025 57.0373H7.9958C7.87235 57.0373 7.84766 57.0126 7.84766 56.8645V53.3336C7.84766 53.0867 7.84766 52.9632 8.0205 52.7657C8.26741 52.4941 9.13161 52.3706 9.47729 52.3706C9.9958 52.3706 10.4649 52.4694 10.7859 52.9138C11.181 52.4941 11.6007 52.3706 12.1686 52.3706C13.0822 52.3706 13.6007 52.7657 13.6007 53.6793V56.8398H13.5761Z"
        fill="white"
      ></path>
      <path
        d="M16.3708 57.1117C16.0005 57.1117 15.7288 57.0129 15.4079 56.7907V58.9142C15.4079 59.0129 15.3585 59.0623 15.2597 59.0623H14.7906C14.6671 59.0623 14.6177 59.0376 14.6177 58.9142V53.5808C14.6177 53.3586 14.593 53.087 14.7165 52.8895C14.9881 52.4944 15.9017 52.3216 16.3461 52.3216C17.7042 52.3216 18.3955 53.2105 18.3955 54.4944C18.3955 55.7043 17.7288 57.1117 16.3708 57.1117ZM16.1733 52.8154C15.5807 52.8154 15.4079 53.0376 15.4079 53.5808V56.2722C15.6795 56.445 15.9758 56.5685 16.2968 56.5685C17.2597 56.5685 17.6054 55.4574 17.6054 54.6672C17.6054 53.7784 17.2103 52.8154 16.1733 52.8154Z"
        fill="white"
      ></path>
      <path
        d="M21.013 57.1115C19.6796 57.1115 18.9883 55.9263 18.9883 54.7165C18.9883 53.556 19.729 52.3461 21.013 52.3461C22.3216 52.3461 23.013 53.6054 23.013 54.7659C22.9883 55.9263 22.2722 57.1115 21.013 57.1115ZM22.1735 54.6424C22.1735 53.951 21.8031 52.8399 20.9389 52.8399C20.05 52.8399 19.8031 53.9017 19.8031 54.6177C19.8031 55.3584 20.0994 56.593 21.0377 56.593C21.9019 56.593 22.1735 55.5066 22.1735 54.8152V54.6424Z"
        fill="white"
      ></path>
      <path
        d="M28.9377 52.6915L27.629 56.8643C27.6043 56.9384 27.6043 56.9878 27.5056 56.9878H27.2093C27.0858 56.9878 27.0611 56.9878 27.0364 56.8643L26.1722 54.0248L25.308 56.8643C25.2833 56.9878 25.2587 56.9878 25.1352 56.9878H24.8883C24.7648 56.9878 24.7401 56.9631 24.7154 56.8643L23.3821 52.6915C23.3574 52.6421 23.3574 52.5927 23.3574 52.5433C23.3574 52.4693 23.4068 52.4446 23.4809 52.4446H23.9747C24.1229 52.4446 24.1475 52.4693 24.1969 52.6174L25.1599 55.8767L26.1229 52.6174C26.1722 52.4446 26.1475 52.4446 26.3698 52.4446C26.4685 52.4446 26.4685 52.494 26.4932 52.5927L27.5056 55.9014L28.4192 52.6174C28.4438 52.494 28.4685 52.4446 28.6167 52.4446H28.9377C28.9871 52.4446 29.0858 52.4446 29.0858 52.5433C28.9624 52.5927 28.9624 52.6421 28.9377 52.6915Z"
        fill="white"
      ></path>
      <path
        d="M32.7635 56.0993C32.4425 56.7413 31.8993 57.1117 31.1586 57.1117C29.8005 57.1117 29.2573 55.9265 29.2573 54.7166C29.2573 53.4574 29.8252 52.3216 31.2326 52.3216C31.9487 52.3216 32.4919 52.6672 32.7635 53.3092C32.8376 53.4821 32.887 53.6796 32.887 53.8771C32.887 54.05 32.7882 54.124 32.64 54.1487L29.9487 54.5438C29.9981 55.3339 30.3437 56.3463 31.3067 56.3463C31.8993 56.3463 32.2697 55.9759 32.566 55.4821C32.6154 55.3833 32.64 55.2845 32.7882 55.2845C32.9117 55.2845 32.961 55.3833 32.961 55.5067C32.9363 55.7043 32.8376 55.9759 32.7635 56.0993ZM32.0228 53.8277C31.9734 53.3339 31.6277 52.8401 31.0845 52.8401C30.3191 52.8401 29.9981 53.4821 29.924 54.1734L32.0228 53.8277Z"
        fill="white"
      ></path>
      <path
        d="M35.9023 53.2101C35.5567 53.2101 35.5567 52.741 35.0134 52.741C34.5937 52.741 34.4949 53.0126 34.4949 53.3582V56.815C34.4949 56.9138 34.4702 56.9632 34.3715 56.9632H33.9023C33.7789 56.9632 33.7295 56.9632 33.7295 56.815V53.136C33.7295 52.9632 33.7295 52.815 33.8529 52.6669C34.0999 52.42 35.0134 52.2965 35.3591 52.2965C35.7048 52.2965 36.2727 52.3459 36.2727 52.815C36.248 53.0372 36.0999 53.2101 35.9023 53.2101Z"
        fill="white"
      ></path>
      <path
        d="M37.2353 51.6544C36.9636 51.6544 36.7661 51.4816 36.7661 51.1853C36.7661 50.9137 36.9636 50.7161 37.2353 50.7161C37.5316 50.7161 37.7044 50.9137 37.7044 51.21C37.7044 51.4569 37.4822 51.6544 37.2353 51.6544ZM37.6056 56.8396C37.6056 56.963 37.5809 57.0124 37.4575 57.0124H36.939C36.8402 57.0124 36.8155 56.9877 36.8155 56.889V52.5927C36.8155 52.4692 36.8402 52.4445 36.9636 52.4445H37.4328C37.5809 52.4445 37.6056 52.4692 37.6056 52.6174V56.8396Z"
        fill="white"
      ></path>
      <path
        d="M42.2018 56.8154C42.2018 56.9142 42.1771 56.9882 42.0537 56.9882H41.5845C41.4611 56.9882 41.4117 56.9635 41.4117 56.8401V53.8524C41.4117 53.6549 41.4117 53.4327 41.3376 53.2351C41.1648 52.8648 40.7697 52.766 40.3747 52.766C40.0784 52.766 39.708 52.8401 39.5105 53.1117C39.4364 53.2351 39.4117 53.408 39.4117 53.5561V56.8154C39.4117 56.9389 39.387 56.9882 39.2636 56.9882H38.7697C38.6463 56.9882 38.6216 56.9389 38.6216 56.8154V53.2598C38.6216 53.0376 38.6216 52.8895 38.7944 52.7413C39.1401 52.4203 40.0537 52.3216 40.4981 52.3216C41.1401 52.3216 41.8068 52.445 42.0784 53.087C42.1524 53.2845 42.1771 53.5808 42.1771 53.7784V56.8154H42.2018Z"
        fill="white"
      ></path>
      <path
        d="M43.1119 58.4203C43.1366 58.3956 43.1613 58.3709 43.1859 58.3463C43.2106 58.3216 43.2353 58.3216 43.2847 58.2969C43.6304 58.3216 43.902 58.5191 44.2971 58.5191C45.2106 58.5191 45.7785 58.05 45.7785 57.0623V56.6919C45.3341 56.8895 45.0625 57.1611 44.5687 57.1611C43.4082 57.1611 42.8896 56.0747 42.8896 54.9882C42.8896 53.6549 43.6551 52.3216 45.0378 52.3216C45.4329 52.3216 46.544 52.5191 46.544 53.1364V57.2351C46.544 58.445 45.4329 59.1364 44.3958 59.1364C44.223 59.1364 43.8773 59.087 43.6057 58.9635C43.3341 58.8401 43.1366 58.7413 43.1366 58.5438V58.4203H43.1119ZM44.8403 56.5191C45.0131 56.5191 45.7785 56.3709 45.7785 56.1487V53.3092C45.7785 52.9142 45.26 52.7907 44.9637 52.7907C44.0008 52.7907 43.6798 53.9512 43.6798 54.7907C43.6551 55.6055 43.9514 56.5191 44.8403 56.5191Z"
        fill="white"
      ></path>
      <path
        d="M49.9042 51.6544C49.6326 51.6544 49.4351 51.4816 49.4351 51.1853C49.4351 50.9137 49.6326 50.7161 49.9042 50.7161C50.2005 50.7161 50.3733 50.9137 50.3733 51.21C50.3733 51.4569 50.1511 51.6544 49.9042 51.6544ZM50.2993 56.8396C50.2993 56.963 50.2746 57.0124 50.1511 57.0124H49.6326C49.5338 57.0124 49.5091 56.9877 49.5091 56.889V52.5927C49.5091 52.4692 49.5338 52.4445 49.6573 52.4445H50.1264C50.2746 52.4445 50.2993 52.4692 50.2993 52.6174V56.8396Z"
        fill="white"
      ></path>
      <path
        d="M54.916 56.1236C54.916 56.2965 54.8913 56.4199 54.7926 56.5681C54.4963 56.9631 53.6568 57.1113 53.1629 57.1113C51.7802 57.1113 51.0889 56.1977 51.0889 54.8644C51.0889 53.6298 51.8049 52.3459 53.1876 52.3459C53.5333 52.3459 53.8296 52.4446 54.1259 52.6422V50.4446C54.1259 50.3212 54.1753 50.2718 54.2988 50.2718H54.7432C54.8667 50.2718 54.916 50.2965 54.916 50.4199V56.1236ZM54.1259 53.1113C53.879 52.8891 53.6321 52.7903 53.2864 52.7903C52.3234 52.7903 51.9037 53.8273 51.9037 54.6422C51.9037 55.5557 52.2987 56.5681 53.3605 56.5681C53.879 56.5681 54.1506 56.3952 54.1506 55.8767V53.1113H54.1259Z"
        fill="white"
      ></path>
      <path
        d="M59.2103 56.0993C58.8893 56.7413 58.3461 57.1117 57.6053 57.1117C56.2473 57.1117 55.7041 55.9265 55.7041 54.7166C55.7041 53.4574 56.272 52.3216 57.6794 52.3216C58.3955 52.3216 58.9387 52.6672 59.2103 53.3092C59.2843 53.4821 59.3337 53.6796 59.3337 53.8771C59.3337 54.05 59.235 54.124 59.0868 54.1487L56.3955 54.5438C56.4448 55.3339 56.7905 56.3463 57.7535 56.3463C58.3461 56.3463 58.7164 55.9759 59.0127 55.4821C59.0621 55.3833 59.0868 55.2845 59.235 55.2845C59.3584 55.2845 59.4078 55.3833 59.4078 55.5067C59.3584 55.7043 59.2597 55.9759 59.2103 56.0993ZM58.4695 53.8277C58.4201 53.3339 58.0745 52.8401 57.5313 52.8401C56.7658 52.8401 56.4448 53.4821 56.3708 54.1734L58.4695 53.8277Z"
        fill="white"
      ></path>
      <path
        d="M63.0404 56.124C63.0404 56.2969 63.0157 56.5191 62.9169 56.6672C62.6453 57.0376 61.8305 57.1117 61.4107 57.1117C60.6206 57.1117 59.8799 56.8154 59.8799 55.9018C59.8799 54.7413 61.4107 54.445 62.2749 54.1734V53.729C62.2749 53.2598 62.2009 52.7907 61.6083 52.7907C61.2379 52.7907 60.991 53.0129 60.8675 53.3586C60.8428 53.3833 60.8428 53.4327 60.8182 53.4821C60.7441 53.6549 60.6453 53.7537 60.4725 53.7537C60.2503 53.7537 60.1268 53.5808 60.1268 53.3833C60.1268 52.6425 61.1391 52.3463 61.7317 52.3463C62.6947 52.3463 63.0404 52.8401 63.0404 53.7537V56.124ZM62.2749 54.6179C61.6823 54.7907 60.67 55.0129 60.67 55.803C60.67 56.3462 61.0404 56.6425 61.5589 56.6425C62.0033 56.6425 62.2749 56.4944 62.2749 56.0006V54.6179Z"
        fill="white"
      ></path>
      <path
        d="M65.3065 57.1117C64.8126 57.1117 64.4176 56.9882 64.0719 56.6425C63.9237 56.4944 63.8003 56.2969 63.8003 56.0993C63.8003 55.8771 63.9731 55.7043 64.1707 55.7043C64.6151 55.7043 64.5657 56.5932 65.3805 56.5932C65.7756 56.5932 66.0719 56.3463 66.0719 55.9265C66.0719 54.7907 63.8991 55.0623 63.8991 53.5561C63.8991 52.766 64.541 52.3216 65.2818 52.3216C65.7262 52.3216 66.22 52.445 66.4916 52.8154C66.5904 52.9388 66.6645 53.0623 66.6645 53.2105C66.6645 53.408 66.5163 53.5561 66.3188 53.5561C65.8497 53.5561 65.9731 52.7907 65.2818 52.7907C64.9114 52.7907 64.6151 53.0376 64.6151 53.4327C64.6151 54.5191 66.8126 54.2228 66.8126 55.803C66.8126 56.6672 66.146 57.1117 65.3065 57.1117Z"
        fill="white"
      ></path>
      <path
        d="M14.5208 44.8149V46.642H3.1875L11.1134 36.8149H4.49614V35.0124H14.8418L6.96528 44.8149H14.5208Z"
        fill="white"
      ></path>
      <path
        d="M18.3977 41.5066C18.3977 43.4573 18.62 45.1363 21.015 45.1363C23.4101 45.1363 23.6323 43.4573 23.6323 41.5066V35.0128H25.6076V41.7289C25.6076 44.8153 24.4471 46.9881 20.9903 46.9881C17.5582 46.9881 16.373 44.84 16.373 41.7289V35.0128H18.3484V41.5066H18.3977Z"
        fill="white"
      ></path>
      <path
        d="M20.2746 28.7906C20.2746 29.5313 19.682 30.1239 18.9412 30.1239C18.2005 30.1239 17.6079 29.5313 17.6079 28.7906C17.6079 28.0498 18.2005 27.4572 18.9412 27.4572C19.682 27.4572 20.2746 28.0498 20.2746 28.7906Z"
        fill="white"
      ></path>
      <path
        d="M24.4728 28.7906C24.4728 29.5313 23.8802 30.1239 23.1395 30.1239C22.3987 30.1239 21.8062 29.5313 21.8062 28.7906C21.8062 28.0498 22.3987 27.4572 23.1395 27.4572C23.8802 27.4572 24.4728 28.0498 24.4728 28.7906Z"
        fill="white"
      ></path>
      <path
        d="M30.4973 27.5311V36.3953L30.5467 36.4447C31.3615 35.3336 32.4232 34.7163 33.8553 34.7163C37.0652 34.7163 37.8059 37.0373 37.8059 39.778V46.6916H35.8306V40.1237C35.8306 38.2472 35.6825 36.5682 33.3862 36.5682C30.6701 36.5682 30.4973 39.0867 30.4973 41.1854V46.7163H28.522V27.5805H30.4973V27.5311Z"
        fill="white"
      ></path>
      <path
        d="M42.7194 46.6419H40.7441V27.5061H42.7194V46.6419Z"
        fill="white"
      ></path>
      <path
        d="M47.9782 39.3336L52.2252 35.0126H54.8671L49.8795 39.9756L55.8054 46.6669H53.1881L47.9782 40.7657V46.6669H46.0029V27.5311H47.9782V39.3336Z"
        fill="white"
      ></path>
      <path
        d="M65.0114 42.9387C64.2707 44.2226 63.2337 45.1362 61.6534 45.1362C59.4559 45.1362 57.9497 43.4078 57.925 41.2597H66.888C67.0855 37.8029 65.1349 34.6918 61.4806 34.6918C57.925 34.6918 55.9497 37.5313 55.9497 40.9881C55.9497 44.3214 58.1226 46.9881 61.53 46.9881C63.7769 46.9881 65.53 45.8523 66.6411 43.9017L65.0114 42.9387ZM61.4806 36.5189C63.1843 36.5189 64.567 37.877 64.8386 39.556H58.0238C58.2707 37.877 59.8263 36.5189 61.4806 36.5189Z"
        fill="white"
      ></path>
    </svg>
  </NuxtLink>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
</script>

<style scoped lang="scss">
.screenreader-only {
  @include hide-visually();
}

.zuehlke-home-link {
  width: 100%;
  height: 100%;

  > svg {
    width: 100%;
    height: 100%;
  }
}
</style>
